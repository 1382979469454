/* This helps to display Lookups inside modals
Maybe it breaks some layout somewhere, we will see :)
*/
/* Breaks scrolling :(
.slds-modal__content {
    overflow: visible;
    overflow-y: inherit;
}
*/

html {
    background-color: white;
}

body {
    /* Hide horizontal scrollbar that can cause usability issues with double x scroll bars in combination with ant table*/
    overflow-x:hidden !important;
}

.lob-no-wrap {
    white-space: nowrap !important;
}

.ant-table-tbody > tr > td {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #f3f3f3;
}

.lobaro-side-menu-collapsed > div {
    padding-left: 10px !important;
    width: 38px !important;
}

.lobaro-side-menu-expanded {
    padding-left: 9px !important;
    padding-right: 0 !important;
    background-color: white !important;
}

